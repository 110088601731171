// EDIT
import React, { Component } from 'react'
import MyFooter from '../../MyFooter/MyFooter'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import 'react-quill/dist/quill.snow.css';
import { actAddProductRequest, actGetProductRequest, actEditProductRequest } from '../../../redux/actions/product';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import callApi from '../../../utils/apiCaller';
import { deleteMany, uploadImage } from '../../../utils/upload'
import Button from '@mui/material/Button';
import { css } from '@emotion/core';
import ClearIcon from '@mui/icons-material/Clear';
import ClipLoader from 'react-spinners/ClipLoader';
import { parse } from 'node-html-parser';
import {config} from 'config';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
let token;
let id;
const override = css`
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
`;

class ActionProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameProduct: '',
      price: 0,
      discountPrice: 0,
      ///Edit
      // categoryId: null,
      categoryId: [],
      ///
      desc: '',
      urls: [],
      longDesc: '',
      isActive: true,
      isBest: false,
      image: '',
      producerId: null,
      redirectToProduct: false,
      dataCategories: [],
      img: null,
      loading: false,
      dataGallery: []
    };
    id = this.props.id
  }

  async componentDidMount() {
    token = localStorage.getItem('_auth');
    const resCategories = await callApi('categories?limit=100', 'GET', null, token);
    this.setState({
      dataCategories: resCategories.data.results
    })
    if (id) {
      const res = await callApi(`products/${id}?isCategory=true`, 'GET', null, token);
      if (res && res.status === 200){
        this.setState({
          nameProduct: res.data.nameProduct,
          price: res.data.price,
          discountPrice: res.data.discountPrice,
          ///Edit
          categoryId: res.data.categories.map(category => category.categoryId),
          ///
          desc: res.data.description,
          longDesc: res.data.longDescription || '',
          isActive: res.data.isActive,
          isBest: res.data.isBest,
          image: res.data.image,
          dataGallery: res.data.gallery
        })
        const urls = this.extractImages(res.data.longDescription);
        this.setState({
          urls: urls
        })
      }
      }
  }

  handleChangeEditor = (value) => {

    this.setState({ desc: value })
  }

  handleChangeImage = (event) => {
    if (event.target.files[0]) {
      const img = event.target.files[0];
      this.setState(() => ({ img }));
      const output = document.getElementById('output');
      output.src = URL.createObjectURL(event.target.files[0]);
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  ///Edit
  handleChangeCategory = async (event) => {
    const {categoryId} = this.state;
    const value = parseInt(event.target.value);
    const index = categoryId.findIndex(item => item === value);
    if(index !== -1) {
      // bỏ chọn category
      categoryId.splice(index, 1);
    } else {
       // chọn category
      categoryId.push(value)
    }
    console.log(categoryId, value)
    this.setState({
      categoryId: categoryId
    })
  }
  ///
  handleChoooseFile = (e) => {
    if(this.state.dataGallery !== null) {
      this.setState({
        dataGallery: [...this.state.dataGallery, ...e.target.files]
      })
    }
  }

  handleChangeRemoveGallery = (index) => {
    // Xóa galley khi tạo sản phẩm
    const data = [...this.state.dataGallery];
    data.splice(index, 1)
    this.setState({
      dataGallery: data
    })
  }

  extractImages = (content) => {
    // Lọc tất cả thẻ src có trong content của editor)
    let urls = parse(content).getElementsByTagName('img').map(item => item.getAttribute('src'))
    return urls;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { dataGallery, nameProduct, price, discountPrice, categoryId, isActive, isBest, desc, longDesc, urls } = this.state;
  
    if( categoryId.length <= 0 || !nameProduct || !price){
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Nhập đầy đủ thông tin`
      })
    } else {
      let { image, img } = this.state;
      let newFiles = [];
      this.setState({
        loading: true
      })
      if (img !== null && img !== image) {
        image = await uploadImage(img);
      }
      if (dataGallery.length > 0){
        for( const file of dataGallery){
          if(typeof(file) === 'object') {
            const builder = await uploadImage(file);
            newFiles.push(builder);
          } else {
            newFiles.push(file);
          }
          
        }
      }
      const newDesc = desc;
      const newsLongDesc = longDesc;
      const newName = nameProduct
      const newImage = image === '' ? null : image;
      const newGallery = newFiles && newFiles.length === 0 ? null : newFiles;
      const newdiscountPrice = discountPrice ? parseInt(discountPrice) : 0;
      const newCategoryId = categoryId;
      let newGalleryFinal = [];
      if(newGallery && newGallery.length) {
        newGallery.forEach((item) => {
          if(typeof(item) === 'string'){
            newGalleryFinal.push(item)
          }
        })
      }
      if (!id) {
        const newProduct = {
          isActive,
          isBest,
          nameProduct: newName,
          price,
          discountPrice: newdiscountPrice,
          categoryId: newCategoryId,
          image: newImage,
          gallery: newGalleryFinal,
          description: newDesc,
          longDescription: newsLongDesc
        }

        await this.props.add_Product(token, newProduct);
        const output = document.getElementById('output');
        output.src = 'http://via.placeholder.com/300x200'
        
        this.setState({
          nameProduct: '',
          price: 0,
          discountPrice: 0,
          desc: '',
          longDesc: '',
          isActive: true,
          isBest: false,
          image: null,
          redirectToProduct: false,
          img: null,
          files: [],
          dataGallery: [],
          loading: false
        })
      } else {
        const editProduct = {
          isActive,
          isBest,
          nameProduct: newName,
          price,
          discountPrice,
          categoryId,
          image: newImage,
          gallery: newGalleryFinal,
          description: newDesc,
          longDescription: newsLongDesc
        }
        await this.props.edit_Product(token, id, editProduct);
        this.setState({
          loading: false,
          redirectToProduct: true,
          dataGallery: newGalleryFinal
        })
      }
      // Tìm những ảnh k sử dụng mà đã được upload lên server
      const newUrls = this.extractImages(newsLongDesc);
      const difference = urls.filter(item => !newUrls.includes(item));
      if ( difference && difference.length > 0) {
        await deleteMany({urls: difference}, token);
      }
    }
  }

  render() {
    const { dataGallery, nameProduct, loading, price, discountPrice, categoryId, longDesc, image, desc, redirectToProduct, dataCategories, isActive, isBest } = this.state;
    let files;

    if(dataGallery && dataGallery.length !== 0) {
      files = dataGallery.map((file, index) => {
        return(
        <div className="galleyItem" key={index}>
          {
            typeof(file) !== 'object' ? 
            <img src={`${config.api.url_BE}/${file}`} alt={nameProduct} />
            :
            <img src={URL.createObjectURL(file)} alt={nameProduct}/>
          }
          <div className="icon-delete" onClick={() => this.handleChangeRemoveGallery(index)}>
            <ClearIcon />
          </div>
        </div>
        )
      })
    } 
    if (redirectToProduct) {
      return <Redirect to='/products'></Redirect>
    }
    return (
      <div className="content-inner">
        {/* Page Header*/}
        <div className='sweet-loading'>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={30}
            color={'#796aeebd'}
            loading={loading}
          />
        </div>
        <header className="page-header">
          <div className="container-fluid">
            <h2 className="no-margin-bottom">Form sản phẩm</h2>
          </div>
        </header>
        {/* Breadcrumb*/}
        <div className="breadcrumb-holder container-fluid">
          <ul className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Trang chủ</a></li>
            <li className="breadcrumb-item active">Sản phẩm</li>
          </ul>
        </div>
        {/* Forms Section*/}
        <section className="forms">
          <div className="container-fluid">
            <div className="row">
              {/* Form Elements */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Thông tin sản phẩm</h3>
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal" onSubmit={(event) => this.handleSubmit(event)} >
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Tên sản phẩm</label>
                        <div className="col-sm-9">
                          <input name="nameProduct" onChange={this.handleChange} value={nameProduct} type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Giá</label>
                        <div className="col-sm-3">
                          <input required name="price" onChange={this.handleChange} value={price} type="number" className="form-control" />
                        </div>
                        <label className="col-sm-3 form-control-label" style={{textAlign: 'center'}}>Giá khuyễn mãi</label>
                        <div className="col-sm-3">
                          <input name="discountPrice" onChange={this.handleChange} value={discountPrice} type="number" className="form-control" />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label htmlFor="fileInput" className="col-sm-3 form-control-label">Hình ảnh</label>
                        <div className="col-sm-9">
                          <input type="file" onChange={this.handleChangeImage} className="form-control-file" accept="image/png, image/gif, image/jpeg"/>
                          <div className="fix-cart-product">
                            <img src={`${config.api.url_BE}/${image}` || 'http://via.placeholder.com/300x200'} id="output" className="fix-img-product" alt="avatar" />
                          </div>
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Mô tả ngắn</label>
                        <div className="col-sm-9">
                        <CKEditor
                          onReady={ editor => {
                              console.log( 'Editor is ready to use!', editor );

                              // Insert the toolbar before the editable area.
                              editor.ui.getEditableElement().parentElement.insertBefore(
                                  editor.ui.view.toolbar.element,
                                  editor.ui.getEditableElement()
                              );

                              // this.editor = editor;
                          } }
                          onError={ ( { willEditorRestart } ) => {
                              // If the editor is restarted, the toolbar element will be created once again.
                              // The `onReady` callback will be called again and the new toolbar will be added.
                              // This is why you need to remove the older toolbar.
                              if ( willEditorRestart ) {
                                  this.editor.ui.view.toolbar.element.remove();
                              }
                          } }
                          onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            this.setState({ desc: data })
                          }}
                          editor={ DecoupledEditor }
                          data={desc}
                          config = { {
                            ckfinder:{
                              //uploadUrl:'https://phukienasang.com/api/upload/editor'
                              uploadUrl:'http://localhost:5002/api/upload/editor'
                            },
                          }}
                        />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-2 form-control-label">Mô tả</label>
                        <div className="col-sm-10">
                        <CKEditor
                          onReady={ editor => {
                              console.log( 'Editor is ready to use!', editor );

                              // Insert the toolbar before the editable area.
                              editor.ui.getEditableElement().parentElement.insertBefore(
                                  editor.ui.view.toolbar.element,
                                  editor.ui.getEditableElement()
                              );

                              // this.editor = editor;
                          } }
                          onError={ ( { willEditorRestart } ) => {
                              // If the editor is restarted, the toolbar element will be created once again.
                              // The `onReady` callback will be called again and the new toolbar will be added.
                              // This is why you need to remove the older toolbar.
                              if ( willEditorRestart ) {
                                  this.editor.ui.view.toolbar.element.remove();
                              }
                          } }
                          onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            this.setState({ longDesc: data })
                            const urls = this.extractImages(data);
                            // upload thêm ảnh lên server
                            if(urls.length >= this.state.urls.length) {
                              this.setState({urls: urls})
                            }
                          }}
                          editor={ DecoupledEditor }
                          data={longDesc}
                          config = { {
                            ckfinder:{
                              // uploadUrl:'https://natecom.net/api/upload/editor'
                              uploadUrl:'http://localhost:5002/api/upload/editor'
                            }
                          }}
                        />
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Danh mục</label>
                        <div className="col-sm-9">
                          {dataCategories && dataCategories.length ?
                            dataCategories.map((item, index) => {
                              return (
                                <div key={index} className="i-checks" style={{ display: 'inline-block', paddingRight: 35 }} >
                                  {
                                    ///Edit
                                    categoryId.includes(item.id) ?
                                      <input id={index} checked onChange={this.handleChangeCategory} type="checkbox" value={item.id} className="radio-template" />
                                      :
                                      <input id={index} onChange={this.handleChangeCategory} type="checkbox" value={item.id} className="radio-template" />
                                    ///
                                  }
                                  <label>{item.nameCategory}</label>
                                </div>
                              )
                            })
                            : null
                          }
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Ảnh mô tả</label>
                        <div className="col-sm-9">
                          <input 
                            type='file' 
                            id='galley-images' 
                            style={{display: 'none'}}
                            onChange={this.handleChoooseFile}
                            multiple
                          />
                          <Button component="label" htmlFor="galley-images" variant="contained">Chọn ảnh</Button>
                          <aside>
                            <div className="container-galley">{files}</div>
                          </aside>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Hiển thị</label>
                        <div className="col-sm-9">
                          <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="isActive"
                              checked={isActive}
                              className="checkbox-template" />
                            <label htmlFor="checkboxCustom1"></label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">Giá Tốt</label>
                        <div className="col-sm-9">
                          <div className="i-checks">
                            <input type="checkbox"
                              onChange={this.handleChange}
                              name="isBest"
                              checked={isBest}
                              className="checkbox-template" />
                            <label htmlFor="checkboxCustom1"></label>
                          </div>
                        </div>
                      </div>
                      <div className="line" />
                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <button onClick={() => {this.setState({redirectToProduct: true})}} className="btn btn-secondary" style={{ marginRight: 2 }}>Hủy</button>
                          <button type="submit" className="btn btn-primary">Lưu lại</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Page Footer*/}
        <MyFooter></MyFooter>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    add_Product: (token, newProduct) => {
      dispatch(actAddProductRequest(token, newProduct))
    },
    get_Product: (token, id) => {
      dispatch(actGetProductRequest(token, id))
    },
    edit_Product: (token, id, data) => {
      dispatch(actEditProductRequest(token, id, data))
    }
  }
}
export default connect(null, mapDispatchToProps)(ActionProduct)