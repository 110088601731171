export const config = {
  api: {
     url: 'https://phukienasang.com/api',
     url_BE: 'https://phukienasang.com'
    

  },
  firebaseConfig: {
    apiKey: "AIzaSyCnJV1AAGCIf1uaHvpkuLWkNHemf3xdsVY",
    authDomain: "ecommerce-d3922.firebaseapp.com",
    projectId: "ecommerce-d3922",
    storageBucket: "ecommerce-d3922.appspot.com",
    messagingSenderId: "295371277672",
    appId: "1:295371277672:web:5e7548bdd3edc2a48ac2dd",
    measurementId: "G-DTWXRPJVHE"
  }
}
